import { render, staticRenderFns } from "./ImageSlide.vue?vue&type=template&id=4a9ef568&scoped=true&"
import script from "./ImageSlide.vue?vue&type=script&lang=js&"
export * from "./ImageSlide.vue?vue&type=script&lang=js&"
import style0 from "./ImageSlide.vue?vue&type=style&index=0&id=4a9ef568&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9ef568",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Markdown: require('/var/www/html/spa-website/components/Markdown.vue').default,IconNext: require('/var/www/html/spa-website/components/icons/IconNext.vue').default})
