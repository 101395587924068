import URI from 'urijs'

export default {
    methods: {
        assetUrl (originalUrl) {
            const uri = new URI(originalUrl)
            if (uri.host() !== 'images.ctfassets.net') {
                return originalUrl
            }

            if (uri.path().endsWith('.gif')) {
                return originalUrl
            }

            const params = new URLSearchParams(uri.query())

            // Configure host
            uri.host('assets.shiseido-professional.cc')

            const pathComponents = []
            const filters = []

            // Configure fit
            if (params.has('fit')) {
                if (params.has('fit', 'pad')) {
                    pathComponents.push('/fit-in')
                }
            } else {
                pathComponents.push('/fit-in')
            }

            // Configure width and height
            if (params.has('w') && params.has('h')) {
                pathComponents.push(`/${params.get('w')}x${params.get('h')}`)
            } else if (params.has('w')) {
                pathComponents.push(`/${params.get('w')}x`)
            } else if (params.has('h')) {
                pathComponents.push(`/x${params.get('h')}`)
            }

            // Configure filter: file format
            if (params.has('fm')) {
                if (params.has('fm', 'avif')) {
                    filters.push('format(webp)')
                } else {
                    filters.push(`format(${params.get('fm')})`)
                }
            }

            // Configure filter: background color
            if (params.has('bg')) {
                const color = params.get('bg')

                if (color.startsWith('rgb:')) {
                    filters.push(`fill(${color.substring(4)})`)
                }
            }

            // Configure filters
            if (filters.length) {
                pathComponents.push(`/filters:${filters.join(':')}`)
            }

            // Reconstruct path
            if (pathComponents.length) {
                uri.path(pathComponents.join('') + uri.path())
            }

            // Remove query string
            uri.query('')

            return uri.toString()
        }
    }
}
