//
//
//
//

import fontMixin from '@/mixins/font'

export default {
    mixins: [fontMixin],
    head: function () {
        return this.$nuxtI18nHead({ addSeoAttributes: true })
    },
    mounted () {
        const resizeObserver = new ResizeObserver(() => {
            this.propagateHeight()
        })

        resizeObserver.observe(this.$el)
    },
    methods: {
        propagateHeight () {
            window.parent.postMessage(this.$el.scrollHeight + 96, '*')
        }
    }
}
