import { get } from 'lodash'
import md5 from 'md5'

export default {
    methods: {
        scrollToElement ({ elementId }) {
            const el = document.getElementById(elementId)

            if (el) {
                // 100 is the rough height of page header
                window.scrollTo({
                    top: el.getBoundingClientRect().top + window.pageYOffset - 100,
                    behavior: 'smooth'
                })
            }
        },
        scrollToSection ({ section }) {
            const elementId = `s-${md5(get(section, 'sys.id'))}`

            this.scrollToElement({ elementId })
        }
    }
}
