//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import assetMixin from '@/mixins/contentful-asset'

export default {
    mixins: [assetMixin],
    props: {
        slide: {
            type: Object,
            required: true
        }
    },
    computed: {
        componentName () {
            return get(this.slide, 'fields.link') ? 'DynamicLink' : 'div'
        },
        to () {
            return this.componentName === 'DynamicLink' ? get(this.slide, 'fields.link.fields.url') : null
        },
        backgroundImage () {
            return get(this.slide, 'fields.media')
        },
        backgroundImageSrc () {
            const url = get(this.backgroundImage, 'fields.file.url')
            const query = new URLSearchParams({ fm: this.$userAgent.browserName === 'Edge' ? 'webp' : 'avif' }).toString()

            return this.assetUrl(`${url}?${query}`)
        },
        mobileBackgroundImage () {
            if (get(this.slide, 'fields.mobileMedia')) {
                return get(this.slide, 'fields.mobileMedia')
            }

            return this.backgroundImage
        },
        mobileBackgroundImageSrc () {
            const url = get(this.mobileBackgroundImage, 'fields.file.url')
            const query = new URLSearchParams({ fm: this.$userAgent.browserName === 'Edge' ? 'webp' : 'avif' }).toString()

            return this.assetUrl(`${url}?${query}`)
        },
        canShowTitle () {
            const canShowTitle = get(this.slide, 'fields.extraFields.show_title', true)
            const canShowTitleMobile = get(this.slide, 'fields.extraFields.show_title_mobile', canShowTitle)

            return (this.$userAgent.isDesktop ? canShowTitle : canShowTitleMobile) && get(this.slide, 'fields.title')
        },
        styles () {
            let justifyContent = 'center'
            switch (get(this.slide, 'fields.extraFields.text_vertical_align')) {
            case 'top':
                justifyContent = 'flex-start'
                break
            case 'bottom':
                justifyContent = 'flex-end'
                break
            }

            return {
                justifyContent,
                height: '100%',
                backgroundImage: `url(${this.$userAgent.isDesktop ? this.backgroundImageSrc : this.mobileBackgroundImageSrc})`,
                backgroundPosition: get(this.slide, 'fields.extraFields.background_position', 'center'),
                backgroundRepeat: 'no-repeat',
                backgroundSize: get(this.slide, 'fields.extraFields.background_size', 'cover'),
                textAlign: get(this.slide, 'fields.extraFields.text_align', 'center'),
                color: get(this.slide, 'fields.extraFields.text_color', 'black')
            }
        }
    },
    methods: { get }
}
